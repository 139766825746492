import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={logo}
          width="100"
          height="100"
          alt="poligonscanLogo"
          onClick={() => window.open("https://polygonscan.com/")}
        />
        <a>04 https?</a>
      </header>
    </div>
  );
}

export default App;
